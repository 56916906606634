import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BlogCard from "../components/BlogCard";
import fetchBlogPosts from "../utils/fetchBlogPosts";

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchBlogPosts().then(setPosts);
  }, []);

  return (
    <>
      <Helmet>
        <title>Simply Knit</title>
        <meta name="description" content="Welcome to Simply Knit!" />
      </Helmet>
      <div className="bg-navy text-white py-16">
        <div className="container mx-auto text-center px-6">
          <h1 className="text-5xl font-bold">Simply Knit</h1>
          <p className="mt-4 text-lg">
            Inspiring stories, techniques, and projects for knitters of all
            levels.
          </p>
        </div>
      </div>
      <main className="bg-white py-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map((post) => (
              <BlogCard
                key={post.slug}
                title={post.title}
                excerpt={post.excerpt}
                slug={post.slug}
                image={post.image}
                publishedDate={post.publishedDate}
              />
            ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
