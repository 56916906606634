import React from "react";
import { Helmet } from "react-helmet-async";
import founderImg from "../assets/images/founder.jpg";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About the Founder | Simply Knit</title>
        <meta
          name="description"
          content="Learn about the founder of Simply Knit, their journey into knitting, and their vision for inspiring knitters worldwide."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About the Founder | Simply Knit" />
        <meta
          property="og:description"
          content="Learn about the founder of Simply Knit, their journey into knitting, and their vision for inspiring knitters worldwide."
        />
        <meta property="og:image" content={founderImg} />
        <meta property="og:url" content="https://simplyknit.com/about" />
        <meta property="og:site_name" content="Simply Knit" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About the Founder | Simply Knit" />
        <meta
          name="twitter:description"
          content="Learn about the founder of Simply Knit, their journey into knitting, and their vision for inspiring knitters worldwide."
        />
        <meta name="twitter:image" content={founderImg} />
      </Helmet>
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <div className="flex flex-col md:flex-row items-center">
            {/* Headshot */}
            <div className="w-full md:w-1/3">
              <img
                src={founderImg} // Replace with the founder's image URL
                alt="Founder Headshot"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
            {/* Founder Description */}
            <div className="w-full md:w-2/3 md:pl-12 mt-8 md:mt-0">
              <h1 className="text-4xl font-bold text-navy">
                About the Founder
              </h1>
              <p className="text-gray-700 mt-4 leading-relaxed">
                Welcome to <strong>Simply Knit</strong>, a blog born out of a
                passion for yarn, creativity, and storytelling. My name is{" "}
                <strong>Sofie Lund</strong>, and I am the founder of this cozy
                corner of the knitting world.
              </p>
              <p className="text-gray-700 mt-4 leading-relaxed">
                My journey into knitting began as a way to unwind from the
                hustle of everyday life. What started as a simple hobby quickly
                transformed into a lifelong love affair with needles and yarn.
                Over the years, I’ve crafted countless projects, from cozy
                scarves to intricate sweaters, and every stitch tells a story.
              </p>
              <p className="text-gray-700 mt-4 leading-relaxed">
                Through <em>Simply Knit</em>, my mission is to inspire, educate,
                and connect knitters worldwide. Whether you’re a beginner or an
                expert, I hope this blog becomes a source of ideas, tips, and
                encouragement to fuel your creativity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
