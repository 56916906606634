import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <header className="bg-white border-b border-gray-200 py-4 sticky top-0 z-50">
    <div className="container mx-auto flex justify-between items-center px-6">
      <Link to="/" className="text-lg font-bold text-navy">
        Simply Knit
      </Link>
      <nav>
        <Link to="/about" className="text-gray-600 hover:text-navy">
          About
        </Link>
      </nav>
    </div>
  </header>
);

export default Header;
