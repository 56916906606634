import { createClient } from "contentful";

// Configure the Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const fetchBlogPosts = async () => {
  try {
    const entries = await client.getEntries({ content_type: "post" });
    const posts = entries.items.map((item) => ({
      title: item.fields.title,
      excerpt: item.fields.excerpt,
      slug: item.fields.slug,
      content: item.fields.content,
      image: `${item.fields.image?.fields.file.url}?w=800&h=600&fit=thumb`,
      publishedDate: item.fields.publishedDate,
    }));
    return posts;
  } catch (error) {
    console.error("Error fetching blog posts:", error);
    return [];
  }
};

export default fetchBlogPosts;
