import React from "react";

const Footer = () => (
  <footer className="bg-white border-t border-gray-200 py-6">
    <div className="container mx-auto text-center text-gray-500">
      © {new Date().getFullYear()} Simply Knit. All rights reserved.
    </div>
  </footer>
);

export default Footer;
